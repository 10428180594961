/* Accordion */

/* Mobile only (override) */
@media screen and (max-width: 654px) {
  .accordion {
    border-bottom: 1px solid var(--gray);
  }
  .accordion > .accordion-header {
    min-height: var(--accordion-height);
    margin-top: 0;
    margin-bottom: 0;
    color: var(--blue);
  }
  .accordion > .accordion-header:hover {
    cursor: pointer;
  }
  .accordion.is-expanded > .accordion-header {
    position: -webkit-sticky;
    position: sticky;
    top: var(--mobile-header-height);
    background: var(--white);
    color: var(--red);
    z-index: 1;
  }
  /* Handle optional subheader above the accordion */
  .subheader + .content .accordion.is-expanded > .accordion-header {
    top: calc(var(--mobile-header-height) + var(--mobile-subheader-height));
  }
  /* Handle icons and body when expanded/collapsed */
  .accordion:not(.is-expanded) > .accordion-header .caret-up,
  .accordion.is-expanded > .accordion-header .caret-down,
  .accordion:not(.is-expanded) > .accordion-body {
    display: none;
  }
  .accordion > .accordion-body {
    padding-bottom: calc(2 * var(--spacing));
  }
  .accordion:not(.is-dense) > .accordion-body {
    padding-top: var(--spacing);
  }
  .accordion.is-nested > .accordion-header,
  .accordion.is-nested > .accordion-body {
    padding-left: calc(3 * var(--spacing));
  }
  .accordion > .accordion-body > *:first-child {
    margin-top: 0;
  }
  .accordion > .accordion-body > *:last-child {
    margin-bottom: 0;
  }
}

/* Block */

.block {
  margin-top: calc(4 * var(--spacing));
  margin-bottom: 0;
}

/* Blockquote */

.quote {
  margin: 0;
}
.quote footer {
  text-align: right;
}
.quote footer cite {
  color: var(--dark-gray);
}

/* Drawer */

.drawer {
  position: fixed;
  top: var(--mobile-header-height);
  right: 0;
  bottom: 0;
  left: 0;
}
@media screen and (min-width: 655px), print {
  .drawer {
    top: var(--desktop-header-height);
  }
}
.drawer:not(.is-hidden) {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1;
}
.drawer,
.drawer .drawer-backdrop,
.drawer .drawer-content {
  overflow-y: auto;
}
.drawer .drawer-backdrop {
  z-index: -1; /* stacks under content (iOS 10, 11) */
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--backdrop);
}
.drawer .drawer-content {
  z-index: 1; /* stacks on top of backdrop */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 85%;
  background: var(--white);
}

/* Heading */

.heading {
  --margin: calc(2 * var(--spacing));
  margin-top: var(--margin);
  margin-bottom: var(--margin);
}
.heading-underline {
  margin-top: 3px;
  border-bottom: 2px solid var(--red);
  width: 100%;
}

/* Popover */

.popover .popover-backdrop {
  position: absolute;
  width: 100%;
  /*z-index: 1; /* stacks under inner (iOS 10, 11) */
  background-color: var(--backdrop);
}
.popover .popover-inner {
  position: absolute;
  background: var(--white);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  border-radius: var(--spacing);
  /*z-index: 2; /* prevents backdrop clickthrough */
  display: flex;
  align-items: baseline;
  overflow-y: auto;
}

/* Table */

.table-container {
  overflow-x: auto; /* makes scrollable horizontally */
}
.table-container.is-bordered {
  border: 1px solid var(--gray); /* sets outer borders, can be combined with .is-rounded */
}

.table {
  border-spacing: 0;
}
.table caption:not(.is-marginless) {
  margin-bottom: var(--spacing);
}
.table td,
.table th {
  padding: var(--spacing);
}

.table.is-narrow td,
.table.is-narrow th {
  padding: var(--spacing) calc(var(--spacing) / 2);
}
.table.is-wide td,
.table.is-wide th {
  padding: var(--spacing) calc(var(--spacing) * 2);
}

@media screen and (min-width: 655px), print {
  .table.is-narrow-desktop td,
  .table.is-narrow-desktop th {
    padding: var(--spacing) calc(var(--spacing) / 2);
  }
}

@media screen and (max-width: 654px) {
  .table.is-wide-mobile td,
  .table.is-wide-mobile th {
    padding: var(--spacing) calc(var(--spacing) * 2);
  }
}

.table.is-bordered {
  border-collapse: collapse;
  /* This removes the outer borders to allow for rounded corners (See #446) */
  /* Wrap <table/> with div.table-container.is-bordered (optionally .is-rounded) */
  border-style: hidden;
}

.table.is-bordered td:not(.is-borderless),
.table.is-bordered th:not(.is-borderless) {
  border: var(--border-min) solid var(--gray);
}

.table.is-top-aligned th,
.table.is-top-aligned td {
  vertical-align: top;
}

/* Button */

/* Button => Generic (applies to all) */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--black);
  background: var(--white);
  border: 1px solid var(--gray);
  font-size: 1rem;
  font-weight: bold;
  padding: calc(2 * var(--spacing));
  border-radius: var(--spacing);
}
@media (hover: hover) {
  .button:hover:not(:disabled) {
    background: var(--light-gray);
  }
}
.button:active:not(:disabled) {
  background: var(--light-gray);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Button => Blue */
.button.is-blue {
  background: var(--blue);
  color: var(--white);
}
@media (hover: hover) {
  .button.is-blue:hover:not(:disabled) {
    background: var(--cornflower-blue);
    border-color: var(--black);
  }
}
.button.is-blue:active:not(:disabled) {
  background: var(--cornflower-blue);
  border-color: var(--black);
}

/* Button => Light Blue */
.button.is-light-blue {
  background: var(--light-blue);
  color: var(--white);
}
@media (hover: hover) {
  .button.is-light-blue:hover:not(:disabled) {
    background: var(--cornflower-blue);
  }
}

/* Button => Light Blue Outlined */
.button.is-light-blue.is-outlined {
  background: var(--white);
  color: var(--light-blue);
  border-color: var(--gray);
}
@media (hover: hover) {
  .button.is-light-blue.is-outlined:hover:not(:disabled) {
    background: var(--gray-blue);
  }
}

/* Button => Yellow */
.button.is-yellow {
  background: var(--yellow);
  border-color: var(--yellow);
}
@media (hover: hover) {
  .button.is-yellow:hover:not(:disabled) {
    background: var(--yellow);
    border-color: var(--black);
  }
}
.button.is-yellow:active:not(:disabled) {
  background: var(--yellow);
  border-color: var(--black);
}

/* Button => Gray */
.button.is-gray {
  background: var(--gray);
  border-color: var(--gray);
}
@media (hover: hover) {
  .button.is-gray:hover:not(:disabled) {
    background: var(--gray);
    border-color: var(--black);
  }
}
.button.is-gray:active:not(:disabled) {
  background: var(--gray);
  border-color: var(--black);
}

/* Button => Tint Gray */
.button.is-tint-gray {
  background: var(--tint-gray);
  border-color: var(--tint-gray);
}

/* Button => Dark Gray */
.button.is-dark-gray {
  background: var(--dark-gray);
  color: var(--white);
  border-color: var(--dark-gray);
}
@media (hover: hover) {
  .button.is-dark-gray:hover:not(:disabled) {
    background: var(--dark-gray);
    border-color: var(--black);
  }
}
.button.is-dark-gray:active:not(:disabled) {
  background: var(--dark-gray);
  border-color: var(--black);
}

/* Button => Green */
.button.is-green {
  background: var(--green);
  border-color: var(--green);
  color: var(--white);
}
@media (hover: hover) {
  .button.is-green:hover:not(:disabled) {
    background: var(--green);
    border-color: var(--black);
  }
}
.button.is-green:active:not(:disabled) {
  border-color: var(--black);
}

/* Button => Red */
.button.is-red {
  background: var(--red);
  border-color: var(--red);
  color: var(--white);
}
@media (hover: hover) {
  .button.is-red:hover:not(:disabled) {
    background: var(--red);
    border-color: var(--black);
  }
}
.button.is-red:active:not(:disabled) {
  border-color: var(--black);
}

/* Button => Plain */
.button.is-ghost,
.button.is-ghost:active {
  background: none;
  border-color: transparent;
  box-shadow: none;
}
@media (hover: hover) {
  .button.is-ghost:hover:not(:disabled) {
    background: none;
  }
}

.button.is-circle {
  border: 0;
}
.button.is-circle:not([class*="padding-"]) {
  padding: 0;
}

.button.is-small {
  padding: calc(0.5 * var(--spacing)) var(--spacing);
  font-size: 0.9rem;
}

.button.is-medium {
  padding: calc(0.75 * var(--spacing)) calc(1.25 * var(--spacing));
  font-size: 0.95rem;
}

a.button {
  line-height: var(--line-height); /* match button (styled in normalize) */
}

/* List */

.list {
  padding: 0;
  list-style-type: none;
}

.list.is-spaced > li {
  padding: calc(var(--spacing) / 2) 0;
}

.list.is-double-spaced > li {
  padding: var(--spacing) 0;
}

/* Message */

.message {
  z-index: 100;
}

@media screen and (max-width: 654px) {
  .message {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

.message .message-dismiss:hover,
.message a:hover {
  color: var(--white);
}

/* Links */

@media screen and (max-width: 654px) {
  .links .subtitle,
  .links ul li {
    padding: calc(0.75 * var(--spacing)) 0;
  }
  .links ul li {
    min-height: 29.9px;
  }
}
@media screen and (min-width: 655px), print {
  .links .subtitle,
  .links ul li {
    min-height: 23.6px;
    padding: 2.75px 0;
  }
}

/* Movie player */

.movie-link.is-selected {
  color: var(--red);
  text-decoration: underline;
}

/* Modal */

.modal,
.modal .modal-backdrop {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.modal {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 999;
}

.modal.is-active {
  display: flex;
}

.modal .modal-backdrop {
  background: var(--backdrop);
}

.modal .modal-box {
  --modal-margin: calc(2 * var(--spacing));
  overflow: auto;
  position: relative;
  margin: var(--modal-margin);
  border-radius: var(--spacing);
}

.modal.is-fullwidth .modal-box {
  width: calc(100% - 2 * var(--modal-margin));
}
@media screen and (min-width: 655px), print {
  .modal.is-fullwidth .modal-box {
    max-width: calc(var(--iphone-6-width) - 2 * var(--modal-margin));
  }
}

/* On mobile, fullsize means entire screen less the x & y margin */

.modal.is-fullsize .modal-box {
  width: calc(100% - 2 * var(--modal-margin));
  height: calc(100% - 2 * var(--modal-margin));
}

/* On desktop, fullsize matches iPhone 6/7/8 dimensions */

@media screen and (min-width: 655px), print {
  .modal.is-fullsize .modal-box,
  .modal.is-fullsize-desktop .modal-box {
    /* iPhone 6/7/8 width/height, less left/right margin */
    max-width: calc(var(--iphone-6-width) - 2 * var(--modal-margin));
    max-height: calc(var(--iphone-6-height) - 2 * var(--modal-margin));
  }
}

.modal .modal-close {
  height: 48px;
  width: 48px;
}

/* Popup */

.popup {
  display: none;
  position: fixed;
  border-radius: calc(2 * var(--spacing));
  box-shadow: 0px -4px 10px 2px rgb(0 0 0 / 20%),
    0 12px 17px 2px rgb(0 0 0 / 14%), 0 5px 22px 4px rgb(0 0 0 / 12%);
  z-index: 1; /* prevents accordion title from sticking out */
}

.popup.is-active {
  display: block;
}

@media screen and (max-width: 654px) {
  .popup {
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 calc(2 * var(--spacing)) calc(4 * var(--spacing));
  }
}

@media screen and (min-width: 655px), print {
  .popup {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

/* Tooltip */

.tooltip {
  display: block;
  position: absolute;
  padding: var(--spacing);
  border-radius: 3px;
  background: var(--dark-gray);
  color: var(--white);
  white-space: nowrap;
  user-select: none;
  z-index: 1;
}

/* Hoverable link with alternating blue/red icons */

.icon-link .is-red {
  display: none;
}
@media (hover: hover) {
  .icon-link:hover .is-red {
    display: unset;
  }
  .icon-link:hover .is-blue {
    display: none;
  }
}
