@import "~normalize.css";

:root {
  /* Colors */
  --blue: #031c50;
  --light-blue: #2945ab;
  --cornflower-blue: #50597e;
  --skyblue: #eaedf7;
  --red: #c80000;
  --green: #006400;
  --black: #111;
  --yellow: #f7b600;
  --light-yellow: #edd189;
  --gray-blue: #f4f6fb;
  --light-gray: #f5f5f5;
  --tint-gray: #eee;
  --gray: #c8c8c8;
  --mid-gray: #969696;
  --dark-gray: #646464;
  --backdrop: rgba(10, 10, 10, 0.7);
  --white: #fff;

  /* Breakpoints */
  --desktop-width: 655px;
  --mobile-max-width: 654px;

  /* Grid */
  --column-spacing: 3px;

  /* Margins */
  --spacing: 5px;

  /* Header */
  --mobile-header-height: 64px;
  --desktop-header-height: 74px;

  /* Subheader */
  --mobile-subheader-height: 62px;
  --desktop-subheader-height: 44px;

  /* Footer */
  --mobile-footer-width: 250px;

  /* Typography */
  font-size: 100%; /* 16px */
  --line-height: 1.15; /* normalize.css */

  /* Sizes originally from Major Second Scale https://type-scale.com/ */
  --h1-font-size: 1.25rem; /* 16px * 1.25 = 20px */
  --h2-font-size: 1.125rem; /* 16px * 1.125 = 18px */
  --h3-font-size: 1rem; /* 16px */
  --h4-font-size: 1rem; /* 16px */
  --h5-font-size: 1rem; /* 16px */
  --h6-font-size: 1rem; /* 16px */
  --h7-font-size: 0.875rem; /* 16px * 0.875 = 14px */
  --h8-font-size: 0.75rem; /* 16px * 0.75 = 12px */
  --h9-font-size: 0.702rem;
  --h10-font-size: 0.624rem;
  --h11-font-size: 0.555rem;

  /* Border */
  --border-min: 1px;

  /* Accordion */
  --accordion-height: 44px;

  /* Form */
  --label-scale-factor: 0.75;

  /* Base mobile device */
  --iphone-6-width: 375px;
  --iphone-6-height: 667px;
}

/* Desktop and print */
@media screen and (min-width: 655px), print {
  :root {
    font-size: 87.5%; /* 14px */
  }
}

/* Global reset */
html {
  overflow-x: hidden;
  box-sizing: border-box;
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: sans-serif;
  background: var(--white);
  color: var(--black);
  display: flex;
  flex-direction: column;
  min-height: 100%; /* height would break position: sticky */
}

@media screen and (max-width: 654px) {
  body {
    line-height: 1.4;
  }
}

/* By default, FF displays sans-serif as Arial. */
@-moz-document url-prefix() {
  body {
    font-family: Arial, sans-serif;
  }
}

h1,
h2,
h3,
h4,
h5 {
  line-height: var(--line-height);
}

h1 {
  font-size: var(--h1-font-size);
}
h2 {
  font-size: var(--h2-font-size);
}
h3 {
  font-size: var(--h3-font-size);
}
h4 {
  font-size: var(--h4-font-size);
}
h5 {
  font-size: var(--h5-font-size);
}
h6 {
  font-size: var(--h6-font-size);
}

h1 {
  margin-top: 0;
}

a {
  text-decoration: none;
  color: var(--light-blue);
}

@media (hover: hover) {
  a:hover:not([class*="button"]):not([class*="is-ghost"]) {
    text-decoration: underline;
    color: var(--red);
  }

  button:hover {
    cursor: pointer;
  }
}

figure {
  margin: 0;
}
figure figcaption {
  margin-top: var(--spacing);
}

img {
  display: flex;
  width: 100%;
}
img.is-size-natural {
  width: auto;
  max-width: 100%;
}
img.is-size-none {
  width: unset;
}

figure.golden {
  padding-top: 61.8%; /* Golden ratio */
  position: relative;
}
figure.golden img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
