.is-unselectable {
  user-select: none;
}
.is-click-through {
  pointer-events: none;
}
.is-clipped {
  overflow: hidden;
}
.is-scrollable-y {
  overflow-y: auto;
}
.is-unscrollable-x {
  overflow-x: hidden;
}
.is-fullwidth {
  width: 100%;
}
.is-fullheight {
  height: 100%;
}
.is-height-auto {
  height: auto;
}
.is-width-max-content {
  width: max-content;
}
.is-width-fit-content {
  width: fit-content;
}
.is-width-auto {
  width: auto;
}
.is-min-width-0 {
  min-width: 0;
}
.is-marginless {
  margin: 0;
}
.is-paddingless {
  padding: 0;
}
.is-borderless {
  border: 0;
}
.is-transparent {
  background: transparent;
}
.is-block {
  display: block;
}
@media screen and (max-width: 654px) {
  .is-block-mobile {
    display: block;
  }
}
@media screen and (min-width: 655px), print {
  .is-block-desktop {
    display: block;
  }
}
.is-inline {
  display: inline;
}
@media screen and (max-width: 654px) {
  .is-inline-mobile {
    display: inline;
  }
}
.is-inline-block {
  display: inline-block;
}
.is-display-contents {
  display: contents;
}
.is-grid {
  display: grid;
}
.is-inline-grid {
  display: inline-grid;
}
.is-grid-template-columns-auto-1fr {
  grid-template-columns: auto 1fr;
}
.is-grid-template-columns-auto-1fr-auto {
  grid-template-columns: auto 1fr auto;
}
.is-grid-template-columns-1fr-auto {
  grid-template-columns: 1fr auto;
}
.is-grid-template-columns-1fr-3fr {
  grid-template-columns: 1fr 3fr;
}
.is-grid-template-columns-1fr-4fr {
  grid-template-columns: 1fr 4fr;
}
.is-grid-template-columns-2fr-1fr-auto {
  grid-template-columns: 2fr 1fr auto;
}
.is-grid-template-columns-3fr-2fr {
  grid-template-columns: 3fr 2fr;
}
.is-grid-template-columns-3fr-2fr-1fr {
  grid-template-columns: 3fr 2fr 1fr;
}
.is-grid-template-columns-repeat-3-1fr {
  grid-template-columns: repeat(3, 1fr);
}
.is-grid-template-columns-repeat-4-min-content {
  grid-template-columns: repeat(4, min-content);
}
.is-grid-column-1-neg-1 {
  grid-column: 1 / -1;
}
.is-grid-column-2-neg-1 {
  grid-column: 2 / -1;
}
.is-grid-column-1-3 {
  grid-column: 1 / 3;
}
.is-grid-column-1-5 {
  grid-column: 1 / 5;
}
.is-grid-column-2-3 {
  grid-column: 2 / 3;
}
.is-grid-column-span-2 {
  grid-column: span 2 / auto;
}
.is-grid-column-span-3 {
  grid-column: span 3 / auto;
}
.is-grid-column-span-4 {
  grid-column: span 4 / auto;
}
.is-grid-column-span-6 {
  grid-column: span 6 / auto;
}
.is-grid-row-span-2 {
  grid-row: span 2 / auto;
}
@media screen and (max-width: 654px) {
  .is-grid-column-span-2-mobile {
    grid-column: span 2 / auto;
  }
}
.is-gap-1px {
  gap: 1px;
}
.is-column-gap-1 {
  column-gap: var(--spacing);
}
.is-column-gap-2 {
  column-gap: calc(2 * var(--spacing));
}
.is-column-gap-3 {
  column-gap: calc(3 * var(--spacing));
}
.is-column-gap-4 {
  column-gap: calc(4 * var(--spacing));
}
.is-column-gap-5 {
  column-gap: calc(5 * var(--spacing));
}
.is-row-gap-0-pt-5 {
  row-gap: calc(0.5 * var(--spacing));
}
.is-row-gap-1 {
  row-gap: var(--spacing);
}
.is-row-gap-2 {
  row-gap: calc(2 * var(--spacing));
}
.is-row-gap-3 {
  row-gap: calc(3 * var(--spacing));
}
.is-row-gap-4 {
  row-gap: calc(4 * var(--spacing));
}
.is-flex {
  display: flex;
}
.is-inline-flex {
  display: inline-flex;
}
.is-flex-column {
  display: flex;
  flex-direction: column;
}
.is-flex-direction-column {
  flex-direction: column;
}
.is-align-items-flex-start {
  align-items: flex-start;
}
@media screen and (max-width: 654px) {
  .is-flex-direction-column-mobile {
    flex-direction: column;
  }
  .is-align-items-flex-start-mobile {
    align-items: flex-start;
  }
}
.is-align-items-flex-end {
  align-items: flex-end;
}
.is-align-items-center {
  align-items: center;
}
.is-align-self-flex-start {
  align-self: flex-start;
}
.is-align-self-center {
  align-self: center;
}
.is-align-self-flex-end {
  align-self: flex-end;
}
.is-align-items-baseline {
  align-items: baseline;
}
.is-justify-content-space-between {
  justify-content: space-between;
}
.is-justify-content-center {
  justify-content: center;
}
.is-justify-content-flex-start {
  justify-content: flex-start;
}
.is-justify-content-flex-end {
  justify-content: flex-end;
}
@media screen and (max-width: 654px) {
  .is-justify-content-center-mobile {
    justify-content: center;
  }
  .is-justify-content-space-between-mobile {
    justify-content: space-between;
  }
}
.is-justify-items-flex-end {
  justify-items: flex-end;
}
.is-justify-self-flex-start {
  justify-self: flex-start;
}
.is-flex-1 {
  flex: 1;
}
@media screen and (max-width: 654px) {
  .is-flex-1-mobile {
    flex: 1;
  }
}
@media screen and (min-width: 655px), print {
  .is-flex-1-desktop {
    flex: 1;
  }
}
.is-flex-0 {
  flex: 0;
}
.is-flex-shrink-0 {
  flex-shrink: 0;
}
.is-flex-wrap-wrap {
  flex-wrap: wrap;
}
.is-order-neg-1 {
  order: -1;
}
.is-order-1 {
  order: 1;
}
@media screen and (max-width: 654px) {
  .is-order-1-mobile {
    order: 1;
  }
}
@media screen and (min-width: 655px), print {
  .is-order-1-desktop {
    order: 1;
  }
}
.is-fixed {
  position: fixed;
}
.is-sticky {
  position: -webkit-sticky;
  position: sticky;
}
.is-relative {
  position: relative;
}
.is-absolute {
  position: absolute;
}
.is-top-0 {
  top: 0;
}
.is-left-0 {
  left: 0;
}
.is-right-0 {
  right: 0;
}
.is-bottom-0 {
  bottom: 0;
}
.is-centered-y {
  top: 50%;
  transform: translateY(-50%);
}
.is-centered-x {
  left: 50%;
  transform: translateX(-50%);
}
.is-float-right {
  float: right;
}
.is-clear-both {
  clear: both;
}
.is-square {
  border-radius: 0;
}
.is-circle {
  border-radius: 50%;
}
.is-rounded {
  border-radius: var(--spacing);
}
.is-rounded-small {
  border-radius: 3px;
}
.is-rounded-top-left {
  border-top-left-radius: var(--spacing);
}
.is-rounded-top-right {
  border-top-right-radius: var(--spacing);
}
.is-rounded-bottom {
  border-bottom-left-radius: var(--spacing);
  border-bottom-right-radius: var(--spacing);
}
.is-rounded-bottom-left {
  border-bottom-left-radius: var(--spacing);
}
.is-rounded-bottom-right {
  border-bottom-right-radius: var(--spacing);
}
.is-flipped-x {
  transform: scaleX(-1);
}
.is-line-height-small {
  line-height: 1;
}
.is-line-height-normal {
  line-height: var(--line-height);
}
.is-line-height-medium {
  line-height: 1.3;
}
.is-line-height-large {
  line-height: 1.4;
}
@media screen and (max-width: 654px) {
  .is-line-height-small-mobile {
    line-height: 1;
  }
  .is-line-height-normal-mobile {
    line-height: var(--line-height);
  }
}
.is-vertical-align-middle {
  vertical-align: middle;
}
.is-resizable {
  resize: both;
}

@media screen and (max-width: 654px) {
  .is-hidden-mobile {
    display: none;
  }
}
@media screen and (min-width: 655px), print {
  .is-hidden-desktop {
    display: none;
  }
}

.is-hidden,
.is-print {
  display: none;
}
.is-invisible {
  visibility: hidden;
}
@media print {
  .is-print {
    display: initial;
  }
}

.is-link,
.is-undecorated-link {
  color: var(--light-blue);
  cursor: pointer;
}
.is-link {
  text-decoration: underline;
}
@media (hover: hover) {
  .is-link:hover,
  .is-undecorated-link:hover {
    color: var(--red);
    text-decoration: underline;
  }
}

.button.is-link,
.button.is-undecorated-link {
  border: 0;
}
.button.is-link {
  text-decoration: none;
}
button.is-link[disabled],
button.is-undecorated-link[disabled] {
  color: var(--dark-gray);
}

.is-clickable {
  cursor: pointer;
}
.is-unclickable {
  cursor: not-allowed;
}

.is-uppercase {
  text-transform: uppercase;
}
.is-underlined {
  text-decoration: underline;
}
.is-undecorated {
  text-decoration: none;
}
.is-italic {
  font-style: italic;
}
.is-strikethrough {
  text-decoration: line-through;
}

.has-text-left {
  text-align: left;
}
.has-text-right {
  text-align: right;
}
.has-text-centered {
  text-align: center;
}
@media screen and (max-width: 654px) {
  .has-text-left-mobile {
    text-align: left;
  }
  .has-text-weight-bold-mobile {
    font-weight: bold;
  }
  .has-text-centered-mobile {
    text-align: center;
  }
}
.has-text-weight-normal {
  font-weight: normal;
}
.has-text-weight-bold {
  font-weight: bold;
}
.has-text-red {
  color: var(--red);
}
.has-text-blue {
  color: var(--blue);
}
.has-text-light-blue {
  color: var(--light-blue);
}
.has-text-green {
  color: var(--green);
}
.has-text-black {
  color: var(--black);
}
.has-text-white {
  color: var(--white);
}
.has-text-gray {
  color: var(--gray);
}
.has-text-mid-gray {
  color: var(--mid-gray);
}
.has-text-dark-gray {
  color: var(--dark-gray);
}
.has-text-nowrap {
  white-space: nowrap;
}
@media screen and (min-width: 655px), print {
  .has-text-nowrap-desktop {
    white-space: nowrap;
  }
}
.has-ellipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.has-text-vertical-bottom-up {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
.has-list-style-type-none {
  list-style-type: none;
}
.has-opacity-50 {
  opacity: 0.5;
}
.has-opacity-65 {
  opacity: 0.65;
}
.has-opacity-75 {
  opacity: 0.75;
}
.has-border-gray {
  border: var(--border-min) solid var(--gray);
}
.has-border-top-gray {
  border-top: var(--border-min) solid var(--gray);
}
.has-border-right-gray {
  border-right: var(--border-min) solid var(--gray);
}
.has-border-bottom-gray {
  border-bottom: var(--border-min) solid var(--gray);
}
.has-border-bottom-light-blue {
  border-bottom: var(--border-min) solid var(--light-blue);
}
.has-border-left-gray {
  border-left: var(--border-min) solid var(--gray);
}
.has-border-y-gray {
  border-top: var(--border-min) solid var(--gray);
  border-bottom: var(--border-min) solid var(--gray);
}
.has-border-x-gray {
  border-right: var(--border-min) solid var(--gray);
  border-left: var(--border-min) solid var(--gray);
}
.has-border-x-white {
  border-right: var(--border-min) solid var(--white);
  border-left: var(--border-min) solid var(--white);
}
.has-border-mid-gray {
  border: var(--border-min) solid var(--mid-gray);
}
.has-border-right-mid-gray {
  border-right: var(--border-min) solid var(--mid-gray);
}
.has-border-dark-gray {
  border: var(--border-min) solid var(--dark-gray);
}
.has-border-green {
  border: var(--border-min) solid var(--green);
}
.has-border-blue {
  border: var(--border-min) solid var(--blue);
}
.has-border-bottom-green {
  border-bottom: var(--border-min) solid var(--green);
}
.has-border-black {
  border: var(--border-min) solid var(--black);
}
.has-border-top-black {
  border-top: var(--border-min) solid var(--black);
}
.has-border-bottom-black {
  border-bottom: var(--border-min) solid var(--black);
}
.has-border-red {
  border: var(--border-min) solid var(--red);
}
.has-border-light-blue {
  border: var(--border-min) solid var(--light-blue);
}
.has-border-right-light-blue {
  border-right: var(--border-min) solid var(--light-blue);
}
.has-border-bottom-light-blue {
  border-bottom: var(--border-min) solid var(--light-blue);
}
.has-border-white {
  border: var(--border-min) solid var(--white);
}
.has-border-bottom-color-mid-gray {
  border-bottom-color: var(--mid-gray);
}
.has-border-bottom-color-green {
  border-bottom-color: var(--green);
}
.has-border-bottom-style-dotted {
  border-bottom-style: dotted;
}
.has-border-bottom-width-medium {
  border-bottom-width: 2px;
}
.has-border-bottom-width-large {
  border-bottom-width: 3px;
}
.has-border-color-red {
  border-color: var(--red);
}
.has-border-width-large {
  border-width: 3px;
}
.has-border-top-right-radius-0 {
  border-top-right-radius: 0;
}
.has-border-bottom-right-radius-0 {
  border-bottom-right-radius: 0;
}
.has-border-top-left-radius-0 {
  border-top-left-radius: 0;
}
.has-border-bottom-left-radius-0 {
  border-bottom-left-radius: 0;
}
.has-border-x-0 {
  border-left: 0;
  border-right: 0;
}
.has-background-gray {
  background: var(--gray);
}
.has-background-mid-gray {
  background: var(--mid-gray);
}
.has-background-dark-gray {
  background: var(--dark-gray);
}
.has-background-tint-gray {
  background: var(--tint-gray);
}
.has-background-light-gray {
  background: var(--light-gray);
}
.has-background-white {
  background: var(--white);
}
.has-background-blue {
  background: var(--blue);
}
.has-background-green {
  background: var(--green);
}
.has-background-red {
  background: var(--red);
}
.has-background-light-blue {
  background: var(--light-blue);
}
.has-background-skyblue {
  background: var(--skyblue);
}
.has-shadow-gray {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.has-shadow-bottom-gray {
  box-shadow: 0 3px 3px -3px #c7c7c7;
}

.is-size-1 {
  font-size: var(--h1-font-size);
}
.is-size-2 {
  font-size: var(--h2-font-size);
}
.is-size-3 {
  font-size: var(--h3-font-size);
}
.is-size-4 {
  font-size: var(--h4-font-size);
}
.is-size-5 {
  font-size: var(--h5-font-size);
}
.is-size-6 {
  font-size: var(--h6-font-size);
}
.is-size-7 {
  font-size: var(--h7-font-size);
}
.is-size-8 {
  font-size: var(--h8-font-size);
}
.is-size-9 {
  font-size: var(--h9-font-size);
}
.is-size-10 {
  font-size: var(--h10-font-size);
}

@media screen and (max-width: 654px) {
  .is-size-2-mobile {
    font-size: var(--h2-font-size);
  }
  .is-size-7-mobile {
    font-size: var(--h7-font-size);
  }
  .is-size-10-mobile {
    font-size: var(--h10-font-size);
  }
}

@media screen and (min-width: 655px), print {
  .is-size-2-desktop {
    font-size: var(--h2-font-size);
  }
  .is-size-6-desktop {
    font-size: var(--h6-font-size);
  }
  .is-size-9-desktop {
    font-size: var(--h9-font-size);
  }
}

.is-z-index-1 {
  z-index: 1;
}
.is-z-index-2 {
  z-index: 2;
}

.margin-1 {
  margin: var(--spacing);
}
.margin-2 {
  margin: calc(2 * var(--spacing));
}
.margin-auto {
  margin: auto;
}
.margin-top-auto {
  margin-top: auto;
}
.margin-top-0 {
  margin-top: 0;
}
.margin-top-1 {
  margin-top: var(--spacing);
}
.margin-top-2 {
  margin-top: calc(2 * var(--spacing));
}
.margin-top-3 {
  margin-top: calc(3 * var(--spacing));
}
.margin-top-4 {
  margin-top: calc(4 * var(--spacing));
}
.margin-top-5 {
  margin-top: calc(5 * var(--spacing));
}
.margin-right-auto {
  margin-right: auto;
}
.margin-right-1 {
  margin-right: var(--spacing);
}
.margin-right-2 {
  margin-right: calc(2 * var(--spacing));
}
.margin-right-3 {
  margin-right: calc(3 * var(--spacing));
}
.margin-right-4 {
  margin-right: calc(4 * var(--spacing));
}
.margin-bottom-auto {
  margin-bottom: auto;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-0-pt-5 {
  margin-bottom: calc(0.5 * var(--spacing));
}
.margin-bottom-1 {
  margin-bottom: var(--spacing);
}
.margin-bottom-2 {
  margin-bottom: calc(2 * var(--spacing));
}
.margin-bottom-3 {
  margin-bottom: calc(3 * var(--spacing));
}
.margin-bottom-4 {
  margin-bottom: calc(4 * var(--spacing));
}
.margin-bottom-5 {
  margin-bottom: calc(5 * var(--spacing));
}
.margin-left-auto {
  margin-left: auto;
}
.margin-left-0 {
  margin-left: 0;
}
.margin-left-1 {
  margin-left: var(--spacing);
}
.margin-left-2 {
  margin-left: calc(2 * var(--spacing));
}
.margin-left-3 {
  margin-left: calc(3 * var(--spacing));
}
.margin-left-4 {
  margin-left: calc(4 * var(--spacing));
}
.margin-left-5 {
  margin-left: calc(5 * var(--spacing));
}
.margin-left-6 {
  margin-left: calc(6 * var(--spacing));
}
.margin-left-7 {
  margin-left: calc(7 * var(--spacing));
}
.margin-left-8 {
  margin-left: calc(8 * var(--spacing));
}
.margin-left-10 {
  margin-left: calc(10 * var(--spacing));
}
.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.margin-y-0-pt-5 {
  margin-top: calc(0.5 * var(--spacing));
  margin-bottom: calc(0.5 * var(--spacing));
}
.margin-y-1 {
  margin-top: var(--spacing);
  margin-bottom: var(--spacing);
}
.margin-y-2 {
  margin-top: calc(2 * var(--spacing));
  margin-bottom: calc(2 * var(--spacing));
}
.margin-y-3 {
  margin-top: calc(3 * var(--spacing));
  margin-bottom: calc(3 * var(--spacing));
}
.margin-y-4 {
  margin-top: calc(4 * var(--spacing));
  margin-bottom: calc(4 * var(--spacing));
}
.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-x-1 {
  margin-left: var(--spacing);
  margin-right: var(--spacing);
}
.margin-x-2 {
  margin-left: calc(2 * var(--spacing));
  margin-right: calc(2 * var(--spacing));
}
.margin-x-3 {
  margin-left: calc(3 * var(--spacing));
  margin-right: calc(3 * var(--spacing));
}
.margin-x-4 {
  margin-left: calc(4 * var(--spacing));
  margin-right: calc(4 * var(--spacing));
}

.padding-1 {
  padding: var(--spacing);
}
.padding-2 {
  padding: calc(2 * var(--spacing));
}
.padding-3 {
  padding: calc(3 * var(--spacing));
}
.padding-4 {
  padding: calc(4 * var(--spacing));
}
.padding-5 {
  padding: calc(5 * var(--spacing));
}
.padding-top-0 {
  padding-top: 0;
}
.padding-top-0-pt-5 {
  padding-top: calc(0.5 * var(--spacing));
}
.padding-top-1 {
  padding-top: var(--spacing);
}
.padding-top-2 {
  padding-top: calc(2 * var(--spacing));
}
.padding-top-3 {
  padding-top: calc(3 * var(--spacing));
}
.padding-top-4 {
  padding-top: calc(4 * var(--spacing));
}
.padding-right-0 {
  padding-right: 0;
}
.padding-right-1 {
  padding-right: var(--spacing);
}
.padding-right-2 {
  padding-right: calc(2 * var(--spacing));
}
.padding-right-3 {
  padding-right: calc(3 * var(--spacing));
}
.padding-right-4 {
  padding-right: calc(4 * var(--spacing));
}
.padding-right-5 {
  padding-right: calc(5 * var(--spacing));
}
.padding-right-6 {
  padding-right: calc(6 * var(--spacing));
}
.padding-right-11 {
  padding-right: calc(11 * var(--spacing));
}
.padding-left-0 {
  padding-left: 0;
}
.padding-left-1 {
  padding-left: var(--spacing);
}
.padding-left-2 {
  padding-left: calc(2 * var(--spacing));
}
.padding-left-3 {
  padding-left: calc(3 * var(--spacing));
}
.padding-left-4 {
  padding-left: calc(4 * var(--spacing));
}
.padding-left-5 {
  padding-left: calc(5 * var(--spacing));
}
.padding-left-6 {
  padding-left: calc(6 * var(--spacing));
}
.padding-left-7 {
  padding-left: calc(7 * var(--spacing));
}
.padding-bottom-0 {
  padding-bottom: 0;
}
.padding-bottom-1 {
  padding-bottom: var(--spacing);
}
.padding-bottom-2 {
  padding-bottom: calc(2 * var(--spacing));
}
.padding-bottom-3 {
  padding-bottom: calc(3 * var(--spacing));
}
.padding-bottom-4 {
  padding-bottom: calc(4 * var(--spacing));
}
.padding-bottom-7 {
  padding-bottom: calc(7 * var(--spacing));
}
.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}
.padding-x-1 {
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}
.padding-x-2 {
  padding-left: calc(2 * var(--spacing));
  padding-right: calc(2 * var(--spacing));
}
.padding-x-3 {
  padding-left: calc(3 * var(--spacing));
  padding-right: calc(3 * var(--spacing));
}
.padding-x-4 {
  padding-left: calc(4 * var(--spacing));
  padding-right: calc(4 * var(--spacing));
}
.padding-x-5 {
  padding-left: calc(5 * var(--spacing));
  padding-right: calc(5 * var(--spacing));
}
.padding-x-6 {
  padding-left: calc(6 * var(--spacing));
  padding-right: calc(6 * var(--spacing));
}
.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.padding-y-pt-75 {
  padding-top: calc(0.75 * var(--spacing));
  padding-bottom: calc(0.75 * var(--spacing));
}
.padding-y-1-pt-5 {
  padding-top: calc(1.5 * var(--spacing));
  padding-bottom: calc(1.5 * var(--spacing));
}
.padding-y-1 {
  padding-top: var(--spacing);
  padding-bottom: var(--spacing);
}
.padding-y-2 {
  padding-top: calc(2 * var(--spacing));
  padding-bottom: calc(2 * var(--spacing));
}
.padding-y-2-pt-5 {
  padding-top: calc(2.5 * var(--spacing));
  padding-bottom: calc(2.5 * var(--spacing));
}
.padding-y-3 {
  padding-top: calc(3 * var(--spacing));
  padding-bottom: calc(3 * var(--spacing));
}
.padding-y-4 {
  padding-top: calc(4 * var(--spacing));
  padding-bottom: calc(4 * var(--spacing));
}
@media screen and (max-width: 654px) {
  .margin-top-0-mobile {
    margin-top: 0;
  }
  .margin-bottom-neg-1-mobile {
    margin-bottom: calc(-1 * var(--spacing));
  }
  .margin-bottom-2-mobile {
    margin-bottom: calc(2 * var(--spacing));
  }
  .margin-bottom-3-mobile {
    margin-bottom: calc(3 * var(--spacing));
  }
  .margin-left-1-mobile {
    margin-left: var(--spacing);
  }
  .margin-x-auto-mobile {
    margin-left: auto;
    margin-right: auto;
  }
  .padding-2-mobile {
    padding: calc(2 * var(--spacing));
  }
  .padding-top-1-mobile {
    padding-top: var(--spacing);
  }
  .padding-top-2-mobile {
    padding-top: calc(2 * var(--spacing));
  }
  .padding-top-3-mobile {
    padding-top: calc(3 * var(--spacing));
  }
  .padding-x-2-mobile {
    padding-left: calc(2 * var(--spacing));
    padding-right: calc(2 * var(--spacing));
  }
  .padding-y-2-mobile {
    padding-top: calc(2 * var(--spacing));
    padding-bottom: calc(2 * var(--spacing));
  }
}
@media screen and (min-width: 655px), print {
  .margin-top-2-desktop {
    margin-top: calc(2 * var(--spacing));
  }
  .margin-right-2-desktop {
    margin-right: calc(2 * var(--spacing));
  }
  .margin-bottom-3-desktop {
    margin-bottom: calc(3 * var(--spacing));
  }
  .margin-left-2-desktop {
    margin-left: calc(2 * var(--spacing));
  }
  .margin-x-2-desktop {
    margin-left: calc(2 * var(--spacing));
    margin-right: calc(2 * var(--spacing));
  }
  .margin-y-1-desktop {
    margin-top: var(--spacing);
    margin-bottom: var(--spacing);
  }
  .padding-y-2-desktop {
    padding-top: calc(2 * var(--spacing));
    padding-bottom: calc(2 * var(--spacing));
  }

  .padding-1-desktop {
    padding: var(--spacing);
  }
  .padding-3-desktop {
    padding: calc(3 * var(--spacing));
  }
  .padding-top-4-desktop {
    padding-top: calc(4 * var(--spacing));
  }
  .padding-bottom-2-desktop {
    padding-bottom: calc(2 * var(--spacing));
  }
  .padding-x-1-desktop {
    padding-left: var(--spacing);
    padding-right: var(--spacing);
  }
  .padding-x-4-desktop {
    padding-left: calc(4 * var(--spacing));
    padding-right: calc(4 * var(--spacing));
  }
}
