/* Buttons */

.button.is-yellow:disabled {
  opacity: 1;
  background: var(--gray);
  color: var(--dark-gray);
  border-color: var(--gray);
}

.button.is-gray:disabled {
  opacity: 1;
}

.radio {
  width: 21px;
  height: 21px;
}

.checkbox {
  width: 29px;
  height: 29px;
}
/* Divider */

.divider {
  border: 0;
  height: 2px;
  background: var(--gray);
  margin: var(--spacing) 0;
}
.divider.is-slim {
  height: 1px;
}
.divider + .divider {
  margin-top: 2px;
}
.divider.is-red {
  background: var(--red);
}
