/* Form Elements */

.input,
.textarea,
.select {
  /* iOS doesn't honor vertical padding in default apperance, also adds dropshadow on inputs */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--blue);
  font-weight: bold;
  padding-left: calc(2 * var(--spacing));
  padding-right: calc(2 * var(--spacing));
  padding-bottom: 1px;
  width: 100%;
}

.input,
/* react-phone-input-2 applies .input custom class first so we need an overwrite with higher specificity */
.react-tel-input .input.form-control,
.textarea,
.select {
  font-size: calc(1rem * 1.125); /* 18 px */
  border: var(--border-min) solid var(--gray);
  border-radius: 3px;
}
.multiselect .button,
.multiselect .menu {
  font-size: calc(1rem * 1.125); /* 18 px */
}

.input:disabled,
.react-tel-input .input.form-control:disabled,
.textarea:disabled,
.select:disabled {
  color: var(--mid-gray);
  -webkit-text-fill-color: var(--mid-gray); /* Safari */
  background: var(--white);
  opacity: 1; /* select has a default 0.7 */
  cursor: not-allowed;
}

.checkbox:disabled,
.radio:disabled {
  cursor: not-allowed;
}

.react-tel-input.is-disabled .special-label,
.has-floating-label input:disabled ~ label,
.has-floating-label textarea:disabled ~ label,
.has-floating-label select:disabled ~ label,
.checkbox-container.is-disabled + label,
.radio-container.is-disabled + label {
  color: var(--mid-gray);
  cursor: not-allowed;
}

/* excluding .textarea b/c its height depends on rows='number' attr */
.input,
.react-tel-input .input.form-control,
.select,
.multiselect .button {
  height: 2.5rem;
}

.react-tel-input.is-invalid .input.form-control,
.react-tel-input.is-invalid .flag-dropdown {
  border-color: var(--red);
}

.select {
  background: var(--white);
}
.select:not([data-value=""]) {
  overflow: hidden;
  white-space: nowrap; /* when applied to select with value="", its parent gains extra 4px in Safari */
  text-overflow: ellipsis;
}
.select ~ img {
  position: absolute;
  right: calc(2 * var(--spacing));
  top: 50%;
  transform: translateY(-50%);
}

.textarea {
  resize: none;
}

.is-placeholder-italic:placeholder-shown {
  font-style: italic;
}

/* TODO deprecate */
.field-helper {
  position: absolute;
  right: calc(2 * var(--spacing));
  top: 50%;
  transform: translateY(-50%);
  /* pointer-events: none; /* click-thru */
}

/* Checkbox, Radio */

.checkbox-container,
.radio-container {
  position: relative;
  display: flex;
}
.radio-container {
  margin-right: calc(2 * var(--spacing));
}
.checkbox,
.radio {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: var(--white);
  border: var(--border-min) solid var(--mid-gray);
}
.checkbox {
  width: 18px;
  height: 18px;
  border-radius: 3px;
}
.radio {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.checkbox:checked,
.radio:checked {
  background: var(--light-blue);
  border-color: var(--light-blue);
}
.checkbox:checked:disabled,
.radio:checked:disabled {
  background: var(--gray);
  border-color: var(--gray);
}
.checkbox + img {
  pointer-events: none; /* click-thru */
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 50%;
  height: 50%;
}
.radio + .radio-circle {
  pointer-events: none; /* click-thru */
  position: absolute;
  background: var(--white);
  height: 50%;
  width: 50%;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.select,
.checkbox,
.checkbox + img,
.radio,
label {
  cursor: pointer;
}
.checkbox-container + label:not([class*="is-line-height-"]),
.radio-container + label:not([class*="is-line-height-"]) {
  line-height: 1;
}
.checkbox-container + label:not([class*="margin-left-"]) {
  margin-left: calc(2 * var(--spacing));
}

.multiselect:focus-within .menu {
  display: block;
}

/* Floating Labels */
/* Based on https://getbootstrap.com/docs/5.0/forms/floating-labels */
/* Another implementation: https://github.com/anydigital/float-label-css */

.has-floating-label {
  display: flex;
  position: relative;
}
.has-floating-label label {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: calc(2 * var(--spacing));
  pointer-events: none;
  color: var(--dark-gray);
  line-height: 1;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
.has-floating-label textarea + label {
  background: transparent;
  margin-top: calc(2 * var(--spacing));
  height: auto;
}
@media (prefers-reduced-motion: reduce) {
  .has-floating-label label {
    transition: none;
    animation: none;
  }
}
.has-floating-label input,
.has-floating-label select,
.has-floating-label textarea {
  padding-top: calc(3 * var(--spacing));
}
.has-floating-label input::placeholder,
.has-floating-label textarea::placeholder {
  opacity: 0;
}
.has-floating-label input:focus ~ label,
.has-floating-label textarea:focus ~ label,
.has-floating-label input:not(:placeholder-shown) ~ label,
.has-floating-label textarea:not(:placeholder-shown) ~ label,
.has-floating-label select:not([data-value=""]) ~ label,
.has-floating-label input:-webkit-autofill ~ label,
.has-floating-label textarea:-webkit-autofill ~ label,
.has-floating-label select:-webkit-autofill ~ label,
.has-floating-label select:focus-within ~ label {
  transform: scale(var(--label-scale-factor)) translateY(-0.5rem)
    translateX(0.15rem);
  animation: grow-label 0.1s forwards;
}

@keyframes grow-label {
  from {
    width: 100%;
  }
  to {
    width: 115%;
  }
}

/* Telephone Input Overwrites */

.react-tel-input .special-label {
  background: transparent;
  display: block;
  top: 1px;
  left: 60px;
  padding: 0;
  font-size: calc(1rem * var(--label-scale-factor));
  color: var(--dark-gray);
  z-index: 1;
}
.react-tel-input .form-control {
  width: 100%;
  line-height: inherit;
}
.react-tel-input .form-control.open,
.react-tel-input .flag-dropdown.open {
  z-index: auto;
}
.react-tel-input .selected-flag .arrow {
  margin-top: 0;
}
.react-tel-input .flag-dropdown .country-list .flag {
  top: var(--spacing);
}
.react-tel-input.is-disabled .flag-dropdown .flag {
  -webkit-filter: grayscale(70%);
  filter: grayscale(70%);
}
