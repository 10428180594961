/* Specific Rules */

.datepicker {
  width: 291px;
  min-height: 495px;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.input-challenge {
  width: 2.5rem;
}

@media (max-width: 410px) {
  .extend-hold span:first-of-type {
    display: none;
  }
}
@media (min-width: 411px) {
  .extend-hold span:nth-of-type(2) {
    display: none;
  }
}

/* Stepper */

.stepper-header {
  position: -webkit-sticky;
  position: sticky;
  top: var(--mobile-header-height);
  z-index: 2; /* .react-tel-input .special-label has z-index: 1 */
}

@media screen and (min-width: 655px), print {
  .stepper-header {
    top: var(--desktop-header-height);
  }
}

.stepper-button[disabled] {
  opacity: 1;
}

.stepper-circle {
  width: 23px;
  height: 23px;
  border-radius: 100%;
}

.stepper-tab.has-vertical-line::before {
  content: "";
  position: absolute;
  left: 21px; /* 1px border + 10px + (23px # width / 2) - (3px line width / 2) */
  border-left: 3px solid var(--gray);
  top: -8px; /* 10px padding-bottom + 1px border - 3px gap */
  bottom: -8px;
}

.stepper-tab.has-vertical-line.is-line-extended::before {
  top: -25px; /* button height 62px - 1px border - 10px padding-top - 23px # height - 3px gap */
}

.stepper-tab.has-vertical-line.is-line-shortened::before {
  bottom: 0;
}

.stepper-tab.has-vertical-line.is-open::before {
  top: 100%;
}

.stepper-subtitle {
  margin-top: 3px;
  line-height: 1.1875; /* to match 19px icon */
}

.stepper-chevron {
  margin-right: 1px; /* to align with Price Details which has a right border 1px */
}
@media screen and (max-width: 654px) {
  .stepper-chevron {
    margin-right: calc(2 * var(--spacing) + 1px);
  }
}

/* Payment Editor */

.payment-column {
  width: 60px;
}

.payment-row,
.payment-column .payment-status {
  height: 29px; /* checkbox */
}

.payment-row .payment-item {
  min-width: 72.05px;
}

.payment-row .payment-amount {
  min-width: 35.59px;
}

/* Airline picker */

.airline-picker select:not([data-value=""]) ~ label {
  min-width: 121px; /* to accommodate longer airline names */
}
