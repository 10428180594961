/* Global overwrites */

@media screen and (min-width: 655px), print {
  :root {
    font-size: 100%; /* 16px */
  }
}

body {
  line-height: 1.4;
}

/* Flight editor */
pre {
  overflow-x: auto;
}
