/* Container */

.container {
  padding: var(--spacing) calc(2 * var(--spacing));
}
@media screen and (min-width: 655px), print {
  .container {
    max-width: var(--desktop-width);
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

/* Columns */

.columns {
  display: flex;
  margin: calc(-1 * var(--column-spacing));
}
.columns.is-6 {
  margin: calc(-2 * var(--column-spacing));
}
.columns.is-6 > .column {
  padding: calc(2 * var(--column-spacing));
}
@media screen and (min-width: 655px), print {
  .columns.is-12-desktop {
    margin: calc(-4 * var(--column-spacing));
  }
  .columns.is-12-desktop > .column {
    padding: calc(4 * var(--column-spacing));
  }
}
.columns.is-centered {
  align-items: center;
}
.columns.is-spaced-between {
  justify-content: space-between;
}
.columns.is-multiline {
  flex-wrap: wrap;
}
.column {
  flex: 1;
  max-width: 100%;
  padding: var(--column-spacing);
}
.column.is-narrow {
  flex: none;
}
.column.is-1 {
  flex: none;
  width: 8.33333%;
}
.column.is-2 {
  flex: none;
  width: 16.66667%;
}
.column.is-3 {
  flex: none;
  width: 25%;
}
.column.is-4 {
  flex: none;
  width: 33.33333%;
}
.column.is-5 {
  flex: none;
  width: 41.66667%;
}
.column.is-6 {
  flex: none;
  width: 50%;
}
.column.is-7 {
  flex: none;
  width: 58.33333%;
}
.column.is-8 {
  flex: none;
  width: 66.66667%;
}
/* .column.is-9 {
  flex: none;
  width: 75%;
}
.column.is-10 {
  flex: none;
  width: 83.33333%;
}
.column.is-11 {
  flex: none;
  width: 91.66667%;
} */
.column.is-12 {
  flex: none;
  width: 100%;
}
@media screen and (max-width: 654px) {
  .column.is-4-mobile {
    flex: none;
    width: 33.33333%;
  }
  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }
}
@media screen and (min-width: 655px), print {
  .column.is-4-desktop {
    flex: none;
    width: 33.33333%;
  }
  .column.is-5-desktop {
    flex: none;
    width: 41.66667%;
  }
  .column.is-8-desktop {
    flex: none;
    width: 66.66667%;
  }
  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }
}
@media screen and (min-width: 655px), print {
  .column.is-offset-4-desktop {
    margin-left: 33.33333%;
  }
}

/* Scroll offset */
/* When an achor link is clicked, the content will be hidden behind the sticky header(s). */
/* To fix this, apply this class directly on the element with the target ID: */
/* Does the page show a subheader? Use -subheader class, else -header. */
/* See https://stackoverflow.com/a/24298427 and https://stackoverflow.com/a/38106970 */

.is-anchored-header:target,
.is-anchored-subheader:target,
.is-anchored-subheader-mobile:target,
.is-anchored-header-desktop:target {
  pointer-events: none; /* target itself is not clickable */
}

.is-anchored-header:target > *,
.is-anchored-subheader:target > *,
.is-anchored-subheader-mobile:target > *,
.is-anchored-header-desktop:target > * {
  pointer-events: auto; /* restore click on children */
}

.is-anchored-header:target::before,
.is-anchored-subheader:target::before,
.is-anchored-subheader-mobile:target::before,
.is-anchored-header-desktop:target::before {
  --offset: var(--mobile-header-height);
  content: "";
  display: block;
  visibility: hidden;
  user-select: none;
  height: var(--offset);
  margin-top: calc(-1 * var(--offset));
}

.is-anchored-subheader:target::before {
  --offset: calc(var(--mobile-header-height) + var(--mobile-subheader-height));
}

@media screen and (max-width: 654px) {
  .is-anchored-subheader-mobile:target::before {
    --offset: calc(
      var(--mobile-header-height) + var(--mobile-subheader-height)
    );
  }
}

@media screen and (min-width: 655px), print {
  .is-anchored-header:target::before,
  .is-anchored-header-desktop:target::before {
    --offset: var(--desktop-header-height);
  }
  .is-anchored-subheader:target::before {
    --offset: calc(
      var(--desktop-header-height) + var(--desktop-subheader-height)
    );
  }
}

/* Section */

.title {
  margin-top: 0;
  margin-bottom: 0;
}
.subtitle {
  margin: 0;
}

/* Banner */

.banner {
  background: var(--light-yellow);
}
.banner p {
  margin: 0 auto;
}
.banner p a {
  color: var(--black);
}
.banner p a img {
  margin-top: -2px;
}

/* Header */

.header,
.subheader {
  position: -webkit-sticky;
  position: sticky;
}

.header {
  top: 0;
  z-index: 3; /* makes tour drawer stack on top of subheader */
}

@media screen and (max-width: 654px) {
  .header {
    height: var(--mobile-header-height);
  }
}

@media screen and (min-width: 655px), print {
  .header {
    height: var(--desktop-header-height);
  }
}

/* Subheader */

.subheader {
  z-index: 2; /* prevents .golden images from sticking out */
  background: var(--white);
  top: var(--mobile-header-height);
  height: var(--mobile-subheader-height);
}
.subheader + .content .container {
  padding-top: 0;
}

/* Space out page content that does NOT follow subheader */
:not(.subheader) + .content .container:not([class*="padding-"]) {
  padding-top: calc(4 * var(--spacing));
}

@media screen and (min-width: 655px), print {
  .subheader {
    top: var(--desktop-header-height);
    height: var(--desktop-subheader-height);
  }

  /* Space out page content that does NOT follow subheader,
  or that does follow but the subheader is hidden */
  :not(.subheader:not(.is-hidden-desktop))
    + .content
    .container:not([class*="padding-"]) {
    padding-top: calc(7 * var(--spacing));
  }

  .content .container {
    padding-bottom: calc(4 * var(--spacing));
  }
}

/* Headline */

/* iPhone 5/5S/SE 1*/
@media screen and (max-width: 360px) {
  .subheader .headline {
    font-size: var(--h3-font-size);
  }
  .subheader .button {
    padding: calc(1.5 * var(--spacing)) var(--spacing);
    font-size: var(--h7-font-size);
  }
}
@media screen and (max-width: 654px) {
  .subheader .headline {
    line-height: 1;
    flex-direction: column;
  }
}
@media screen and (min-width: 655px), print {
  .subheader .headline {
    font-size: 1.802rem;
  }
}

/* Content */

/* Sticky footer https://css-tricks.com/couple-takes-sticky-footer/#there-is-flexbox */
.content {
  flex: 1 0 auto;
}

/* Footer */

/* Markdown */

.markdown {
  word-wrap: break-word; /* wrap links */
}

.markdown h2:not([class*="margin-"]):not([class*="columns"]),
.markdown h3:not([class*="margin-"]):not([class*="columns"]),
.markdown h4,
.markdown h5,
.markdown h6 {
  margin: 0;
}

.markdown p {
  margin-top: 0;
}

/* Print */

@media print {
  .header,
  .subheader {
    position: static;
  }

  .banner,
  .header.has-background-blue,
  .footer.has-background-light-gray {
    background: var(--white);
  }

  .header img:not(.is-print) {
    display: none;
  }
  .header small {
    color: var(--blue);
  }

  .subheader .has-shadow-bottom-gray {
    box-shadow: none;
  }
}
